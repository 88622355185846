import '../css/app.scss';

window.Tether = require('tether');

require('popper.js/dist/popper');
require('bootstrap/dist/js/bootstrap.bundle.min');

$(function () {
    $('[data-toggle="tooltip"]').tooltip();
});
